function isCSVString(csvdata) {
  const rowsArray = csvdata.toString().split('\n');
  if (!csvdata || csvdata.toString() === '') {
    return false;
  }

  if (rowsArray.length === 0) {
    return false;
  }

  if (rowsArray.length > 1) {
    const firstRow = rowsArray[0].split(',');
    const secondRow = rowsArray[1].split(',');
    return firstRow.length === secondRow.length;
  }
  return false;
}

export { isCSVString };
